import React, { useContext, useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import '../../../styles/global.css';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import db, { auth } from '../../../backend/firebase/firebaseConfig';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import EnvelopPicture from '../../../assets/images/envelop-picture.png';
import ParcelPicture from '../../../assets/images/parcel-picture.png';
import CryptoJS from 'crypto-js';
import TImage from '../../../assets/images/TImage-black.png';

const PurchaseQRCode = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [qrCodeValue, setQRCodeValue] = useState(''); // Value to be rendered in QR code
  const [uniqueCode, setUniqueCode] = useState(''); // Unique identifier for activation and display
  const [productDetails, setProductDetails] = useState({});
  const [envelopeScanText, setEnvelopeScanText] = useState(t.scanTextOption1);
  const [parcelScanText, setParcelScanText] = useState(t.scanTextOption1);
  const [codeType, setCodeType] = useState('QRCode');

  const navigate = useNavigate();

  const encryptionKey = 'your-encryption-key'; // Replace with a secure key in production

  useEffect(() => {
    const checkIdentityVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().identityVerified === false) {
          navigate('/account-verification');
        }
      }
    };

    checkIdentityVerification();
  }, [navigate]);

  const handleQRCodeClick = () => {
    navigate(`/activate-qr-code?q=${uniqueCode}`);
  };

  const handlePurchase = async (product, size, scanText, codeType = 'QRCode') => {
    const user = auth.currentUser;
    if (user) {
      let newUniqueCode;
      let qrCodeValueToSet;

      if (codeType === 'TCode') {
        newUniqueCode = `TCode-${Date.now()}`;
        setUniqueCode(newUniqueCode);
        // Encrypt the unique code
        const encryptedCode = CryptoJS.AES.encrypt(newUniqueCode, encryptionKey).toString();
        setQRCodeValue(encryptedCode);
        qrCodeValueToSet = encryptedCode;
      } else {
        newUniqueCode = `QR-${Date.now()}`;
        setUniqueCode(newUniqueCode);
        const urlValue = `https://logihash.trustecvalley.com/check-qr-code?q=${newUniqueCode}`;
        setQRCodeValue(urlValue);
        qrCodeValueToSet = urlValue;
      }

      const qrCodeData = {
        qrCodeUID: newUniqueCode,
        creationTimestamp: serverTimestamp(),
        lastChangeTimestamp: serverTimestamp(),
        IDOfOwner: [user.uid],
        active: false,
        new: true,
        deactivateBy: '',
        senderName: '',
        senderStreet: '',
        senderHouseNumber: '',
        senderPostalCode: '',
        senderCity: '',
        senderState: '',
        recipientName: '',
        recipientStreet: '',
        recipientHouseNumber: '',
        recipientPostalCode: '',
        recipientCity: '',
        recipientState: '',
        recipientLogiHashID: '',
        recipientPhoneNumber: '',
        status: '',
        notificationTime: '',
        qrCodeProduct: product,
        qrCodeProductSize: size,
        qrCodeWarnings: [],
        scanText: scanText,
        numberOfScans: 0,
        codeType: codeType,
      };

      // Create document in 'inactiveQRCodes' collection
      await setDoc(doc(db, 'inactiveQRCodes', newUniqueCode), qrCodeData);
      setProductDetails({ product, size });
      setCodeType(codeType); // Update the state
    }
  };

  return (
    <div className="white-page-content">
      <div className="purchase-qr-code-page">
        <h1>{t.titlePurchaseQrCode}</h1>
        <p className="mobile-app-note-container">{t.descriptionPurchaseQrCode}</p>

        {!qrCodeValue && (
          <div className="image-wrapper">
            <div className="envelope-image-wrapper">
              <img src={EnvelopPicture} alt="Postal Envelope" className="envelope-image" />
              <label htmlFor="envelopeScanText" className="message-label">
                {t.chooseMessage}:
              </label>
              <select
                id="envelopeScanText"
                name="envelopeScanText"
                onChange={(e) => setEnvelopeScanText(e.target.value)}
                value={envelopeScanText}
                className="message-select"
                required
              >
                <option value="scanTextOption1">{t.scanTextOption1}</option>
                <option value="scanTextOption2">{t.scanTextOption2}</option>
                <option value="scanTextOption3">{t.scanTextOption3}</option>
              </select>
              <button
                className="button-purchase"
                onClick={() => handlePurchase('ENVELOPE', 'DIN_C6/5', envelopeScanText, 'QRCode')}
              >
                {t.purchaseButtonEnvelopeQRCode} <FontAwesomeIcon icon={faShoppingCart} />
              </button>
              <button
                className="button-purchase"
                onClick={() => handlePurchase('ENVELOPE', 'DIN_C6/5', envelopeScanText, 'TCode')}
              >
                {t.purchaseButtonEnvelopeTCode || 'Purchase Envelope T-Code'}{' '}
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
            <div className="parcel-image-wrapper">
              <img src={ParcelPicture} alt="Parcel Cartoon" className="parcel-image" />
              <label htmlFor="parcelScanText" className="message-label">
                {t.chooseMessage}{' '}
              </label>
              <select
                id="parcelScanText"
                name="parcelScanText"
                onChange={(e) => setParcelScanText(e.target.value)}
                value={parcelScanText}
                className="message-select"
                required
              >
                <option value="scanTextOption1">{t.scanTextOption1}</option>
                <option value="scanTextOption2">{t.scanTextOption2}</option>
                <option value="scanTextOption3">{t.scanTextOption3}</option>
              </select>
              <button
                className="button-purchase"
                onClick={() => handlePurchase('PARCEL', '2_KG', parcelScanText, 'QRCode')}
              >
                {t.purchaseButtonParcelQRCode} <FontAwesomeIcon icon={faShoppingCart} />
              </button>
              <button
                className="button-purchase"
                onClick={() => handlePurchase('PARCEL', '2_KG', parcelScanText, 'TCode')}
              >
                {t.purchaseButtonParcelTCode || 'Purchase Parcel T-Code'}{' '}
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          </div>
        )}

        {qrCodeValue && (
          <div className="image-wrapper">
            {productDetails.product === 'ENVELOPE' && (
              <div className="envelope-image-wrapper">
                <img src={EnvelopPicture} alt="Postal Envelope" className="envelope-image" />
                <div id={`qrCodeToPrint-envelope-${uniqueCode}`} className="qr-code-container">
                  <QRCode
                    className="qr-code-overlay-envelope"
                    value={qrCodeValue}
                    size={50}
                    level="H"
                    imageSettings={
                      codeType === 'TCode'
                        ? {
                            src: TImage,
                            height: 15,
                            width: 15,
                            excavate: true,
                          }
                        : null
                    }
                  />
                  <p className="qr-code-text-envelope">{uniqueCode}</p>
                  <p className="qr-code-message-envelope">
                    {t[envelopeScanText] || t.scanTextOption1}
                  </p>
                </div>
              </div>
            )}
            {productDetails.product === 'PARCEL' && (
              <div className="parcel-image-wrapper">
                <img src={ParcelPicture} alt="Parcel Cartoon" className="parcel-image" />
                <div id={`qrCodeToPrint-parcel-${uniqueCode}`} className="qr-code-container">
                  <QRCode
                    className="qr-code-overlay-parcel"
                    value={qrCodeValue}
                    size={50}
                    level="H"
                    imageSettings={
                      codeType === 'TCode'
                        ? {
                            src: TImage,
                            height: 15,
                            width: 15,
                            excavate: true,
                          }
                        : null
                    }
                  />
                  <p className="qr-code-text-parcel">{uniqueCode}</p>
                  <p className="qr-code-message-parcel">
                    {t[parcelScanText] || t.scanTextOption1}
                  </p>
                </div>
              </div>
            )}
            <p>
              {t.qrCodeValue} : {uniqueCode}
            </p>
            <div className="button-activate-container">
              <button className="button-activate" onClick={handleQRCodeClick}>
                {t.activateQRCode}
              </button>
            </div>
            <p>{t.qrCodeNote}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseQRCode;
