import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import db, { auth } from '../../backend/firebase/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBox } from '@fortawesome/free-solid-svg-icons';
import TrustecValleyLogo from '../../assets/images/Trustec_Valley_Logo.png';
import '../../styles/global.css';
import CryptoJS from 'crypto-js'; // Import CryptoJS for encryption
import TImage from '../../assets/images/TImage-black.png'; // Import the 'T' image

const ActiveQRCodes = () => {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language].dashboard;
  const [activeQRCodes, setActiveQRCodes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin check
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login check

  const navigate = useNavigate();

  const encryptionKey = 'your-encryption-key'; // Replace with your actual encryption key

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setIsAdmin(docSnap.data().admin || false); // Check if user is admin
        }
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    const fetchActiveQRCodes = async () => {
      const userUID = auth.currentUser.uid;
      const activeQRCodesRef = collection(db, 'activeQRCodes');

      // First query to check if IDOfOwner is a string
      const queryString = query(activeQRCodesRef, where('IDOfOwner', '==', userUID));
      const querySnapshotString = await getDocs(queryString);

      // Filter documents where IDOfOwner is a string
      const qrCodesString = querySnapshotString.docs
        .map((doc) => (doc.data().IDOfOwner === userUID ? { id: doc.id, ...doc.data() } : null))
        .filter(Boolean);

      // Second query to check if IDOfOwner is an array
      const queryArray = query(activeQRCodesRef, where('IDOfOwner', 'array-contains', userUID));
      const querySnapshotArray = await getDocs(queryArray);

      // Filter documents where IDOfOwner includes userUID
      const qrCodesArray = querySnapshotArray.docs
        .map((doc) => (doc.data().IDOfOwner.includes(userUID) ? { id: doc.id, ...doc.data() } : null))
        .filter(Boolean);

      // Combine results from both queries
      const combinedQRCodes = [...qrCodesString, ...qrCodesArray];

      setActiveQRCodes(combinedQRCodes);
    };

    fetchActiveQRCodes();
  }, []);

  useEffect(() => {
    const checkForExpiredQRCodes = async () => {
      const q = query(collection(db, 'activeQRCodes'), where('IDOfOwner', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (docSnapshot) => {
        const qrCodeData = docSnapshot.data();
        if (qrCodeData.deactivationTimestamp && qrCodeData.deactivationTimestamp.toDate() < new Date()) {
          // Deactivate the QR code if deactivationTimestamp has passed
          await programmaticDeactivateQRCode(docSnapshot.id);
        }
      });
    };

    const interval = setInterval(checkForExpiredQRCodes, 60000); // Check every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const programmaticDeactivateQRCode = async (qrCodeId) => {
    const qrCodeRef = doc(db, 'activeQRCodes', qrCodeId);
    const qrCodeDoc = await getDoc(qrCodeRef);
    const updatedData = {
      qrCodeUID: qrCodeId,
      IDOfOwner: '',
      active: false,
      new: false,
      senderUID: '',
      senderName: '',
      senderStreet: '',
      senderHouseNumber: '',
      senderPostalCode: '',
      senderCity: '',
      senderState: '',
      recipientName: '',
      recipientStreet: '',
      recipientHouseNumber: '',
      recipientPostalCode: '',
      recipientCity: '',
      recipientState: '',
      recipientLogiHashID: '',
      recipientPhoneNumber: '',
      status: '',
      notificationTime: '',
      qrCodeWarnings: [],
      deactivationTimestamp: null,
      creationTimestamp: qrCodeDoc.data().creationTimestamp,
      deactivateBy: 'system',
      qrCodeProduct: qrCodeDoc.data().qrCodeProduct,
      qrCodeProductSize: qrCodeDoc.data().qrCodeProductSize,
      lastChangeTimestamp: serverTimestamp(),
      scanText: qrCodeDoc.data().scanText,
      numberOfScans: 0,
      codeType: qrCodeDoc.data().codeType,
    };

    await updateDoc(qrCodeRef, updatedData);
    const inactiveQRCodesRef = doc(db, 'inactiveQRCodes', qrCodeId);
    await setDoc(inactiveQRCodesRef, updatedData);
    await deleteDoc(qrCodeRef);

    // Update state to reflect changes
    setActiveQRCodes((current) => current.filter((qrCode) => qrCode.id !== qrCodeId));
  };

  const deactivateQRCode = async (qrCodeId) => {
    if (window.confirm(t.deactivateWarning)) {
      const qrCodeRef = doc(db, 'activeQRCodes', qrCodeId);
      const qrCodeDoc = await getDoc(qrCodeRef);
      const updatedData = {
        qrCodeUID: qrCodeId,
        IDOfOwner: '',
        active: false,
        new: false,
        senderUID: '',
        senderName: '',
        senderStreet: '',
        senderHouseNumber: '',
        senderPostalCode: '',
        senderCity: '',
        senderState: '',
        recipientName: '',
        recipientStreet: '',
        recipientHouseNumber: '',
        recipientPostalCode: '',
        recipientCity: '',
        recipientState: '',
        recipientLogiHashID: '',
        recipientPhoneNumber: '',
        status: '',
        notificationTime: '',
        qrCodeWarnings: [],
        deactivationTimestamp: null,
        creationTimestamp: qrCodeDoc.data().creationTimestamp,
        deactivateBy: auth.currentUser.uid,
        qrCodeProduct: qrCodeDoc.data().qrCodeProduct,
        qrCodeProductSize: qrCodeDoc.data().qrCodeProductSize,
        lastChangeTimestamp: serverTimestamp(),
        scanText: qrCodeDoc.data().scanText,
        numberOfScans: 0,
        codeType: qrCodeDoc.data().codeType,
      };

      await updateDoc(qrCodeRef, updatedData);
      const inactiveQRCodesRef = doc(db, 'inactiveQRCodes', qrCodeId);
      await setDoc(inactiveQRCodesRef, updatedData);
      await deleteDoc(qrCodeRef);

      // Update state to reflect changes
      setActiveQRCodes((current) => current.filter((qrCode) => qrCode.id !== qrCodeId));
      window.location.reload();
    }
  };

  const handleQRCodeClick = (qrCodeUID) => {
    navigate(`/check-qr-code?q=${qrCodeUID}`);
  };

  const printQRCodepdf = (qrCodeUID) => {
    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`LogiHash-${qrCodeUID}.pdf`);
    });
  };

  const printQRCodepng = (qrCodeUID) => {
    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a link element
      const link = document.createElement('a');
      link.href = imgData;
      link.download = `LogiHash-${qrCodeUID}.png`;

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    });
  };

  const printEnvelopeQRCode = async (qrCodeUID) => {
    // Fetch the QR code data from Firestore
    const qrCodeRef = doc(db, 'activeQRCodes', qrCodeUID);
    const qrCodeDoc = await getDoc(qrCodeRef);

    // Determine the text to use for the scan instruction
    const scanText =
      qrCodeDoc.exists() && qrCodeDoc.data().scanText
        ? t[qrCodeDoc.data().scanText] || t.scanTextOption1
        : t.scanTextOption1;

    const input = document.getElementById(`qrCodeToPrint-${qrCodeUID}`);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a PDF with envelope size (110 mm x 220 mm)
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [110, 220],
      });

      // Add the captured QR code image to the top left of the PDF
      pdf.addImage(imgData, 'PNG', 60, 20, 30, 25);

      // Add the text below the QR code
      pdf.setFontSize(8);
      pdf.text(scanText, 60, 50);

      // Add TrustecValley logo
      const logo = new Image();
      logo.src = TrustecValleyLogo;
      logo.onload = () => {
        const logoWidth = 40; // Set the logo width
        const logoHeight = (logoWidth / logo.width) * logo.height; // Maintain aspect ratio
        pdf.addImage(logo, 'PNG', 150, 20, logoWidth, logoHeight); // Adjust the position and size as needed

        // Add text content to the PDF
        const text = `
              ${t.envelopeLine1} 
              ${t.envelopeLine2} 
              ${t.envelopeLine3}

              ${t.trustecvalleycontact}:
              ${t.trustecvalleyemail}: info@trustecvalley.com
              ${t.trustecvalleywebsite}: www.trustecvalley.com`;

        // Set font size
        pdf.setFontSize(8); // Adjust the font size as needed
        pdf.text(text, 120, 60); // Adjust the position as needed

        // Save the PDF
        pdf.save(`Envelope-LogiHash-${qrCodeUID}.pdf`);
      };
    });
  };

  const renderActiveQRCodes = () => {
    if (activeQRCodes.length > 0) {
      return (
        <div className="table-main-container">
          <h2>{t.activeQRCodes}</h2>
          <table className="table-container">
            <thead>
              <tr>
                <th>{t.status}</th>
                <th>{t.QRCode}</th>
                <th>{t.type}</th>
                <th>{t.qrCodeProduct}</th>
                <th>{t.qrCodeProductSize}</th>
                <th>{t.QRCodeUID}</th>
                <th>{t.lastChangeTimestamp}</th>
                <th>{t.deactivationTimestamp}</th>
                <th>{t.checkQRCode}</th>
                <th>{t.logiHashIDofOwner}</th>
                <th>{t.senderName}</th>
                <th>{t.senderStreet}</th>
                <th>{t.senderPostalCode}</th>
                <th>{t.senderCity}</th>
                <th>{t.senderState}</th>
                <th>{t.senderCountry}</th>
                <th>{t.recipientName}</th>
                <th>{t.recipientStreet}</th>
                <th>{t.recipientHouseNumber}</th>
                <th>{t.recipientPostalCode}</th>
                <th>{t.recipientCity}</th>
                <th>{t.recipientState}</th>
                <th>{t.recipientCountry}</th>
                <th>{t.recipientPhoneNumber}</th>
                <th>{t.recipientLogiHashID}</th>
                <th>{t.numberOfScans}</th>
                {isAdmin && <th>{t.deactivate}</th>}
                {isAdmin && <th>{t.printQRCode} PDF</th>}
                {isAdmin && <th>{t.printQRCode} PNG</th>}
                {isAdmin && <th>{t.printEnvelope}</th>}
              </tr>
            </thead>
            <tbody>
              {activeQRCodes.map((qrCode) => {
                const codeType = qrCode.codeType || 'QRCode'; // Default to 'QRCode' if codeType doesn't exist
                const hasWarnings = qrCode.qrCodeWarnings && qrCode.qrCodeWarnings.length > 0;
                const isExpiringSoon =
                  qrCode.deactivationTimestamp &&
                  qrCode.deactivationTimestamp.toDate() <= new Date(Date.now() + 24 * 60 * 60 * 1000); // Check if deactivationTimestamp is within 24 hours
                const statusClass = hasWarnings
                  ? 'active-circle warning'
                  : isExpiringSoon
                  ? 'active-circle expiring'
                  : 'active-circle';
                const tooltipText = hasWarnings
                  ? t.reportedByUsers
                  : isExpiringSoon
                  ? t.willBeDeactivated
                  : t.activeAndTrusted;

                // Determine QR code value and image settings
                let qrCodeValue;
                let qrCodeImageSettings = null;

                if (codeType === 'TCode') {
                  // Encrypt the QR code UID
                  const encryptedCode = CryptoJS.AES.encrypt(qrCode.qrCodeUID, encryptionKey).toString();
                  qrCodeValue = encryptedCode;
                  qrCodeImageSettings = {
                    src: TImage,
                    height: 15,
                    width: 15,
                    excavate: true,
                  };
                } else {
                  qrCodeValue = `https://logihash.trustecvalley.com/check-qr-code?q=${qrCode.qrCodeUID}`;
                }

                return (
                  <tr key={qrCode.id}>
                    <td>
                      {qrCode.active ? (
                        <span className={statusClass} title={tooltipText}></span>
                      ) : (
                        <span className="inactive-circle"></span>
                      )}
                    </td>
                    <td>
                      <div id={`qrCodeToPrint-${qrCode.qrCodeUID}`}>
                        <QRCode
                          value={qrCodeValue}
                          size={70}
                          level="H"
                          imageSettings={qrCodeImageSettings}
                        />
                        <p>{qrCode.qrCodeUID}</p>
                      </div>
                    </td>
                    <td>{codeType}</td> {/* Display the code type */}
                    <td>
                      {qrCode.qrCodeProduct === 'ENVELOPE' && (
                        <>
                          {t.envelope} <FontAwesomeIcon icon={faEnvelope} />
                        </>
                      )}
                      {qrCode.qrCodeProduct === 'PARCEL' && (
                        <>
                          {t.parcel} <FontAwesomeIcon icon={faBox} />
                        </>
                      )}
                    </td>
                    <td>{qrCode.qrCodeProductSize}</td>
                    <td>{qrCode.qrCodeUID}</td>
                    <td>{qrCode.lastChangeTimestamp?.toDate().toLocaleString()}</td>
                    <td>
                      {qrCode.deactivationTimestamp
                        ? qrCode.deactivationTimestamp.toDate().toLocaleString()
                        : t.forever}
                    </td>
                    <td>
                      <button className="button-check" onClick={() => handleQRCodeClick(qrCode.qrCodeUID)}>
                        {t.checkQRCode}
                      </button>
                    </td>
                    <td>{qrCode.IDOfOwner}</td>
                    <td>{qrCode.senderName}</td>
                    <td>{qrCode.senderStreet}</td>
                    <td>{qrCode.senderPostalCode}</td>
                    <td>{qrCode.senderCity}</td>
                    <td>{qrCode.senderState}</td>
                    <td>{qrCode.senderCountry}</td>
                    <td>{qrCode.recipientName}</td>
                    <td>{qrCode.recipientStreet}</td>
                    <td>{qrCode.recipientHouseNumber}</td>
                    <td>{qrCode.recipientPostalCode}</td>
                    <td>{qrCode.recipientCity}</td>
                    <td>{qrCode.recipientState}</td>
                    <td>{qrCode.recipientCountry}</td>
                    <td>{qrCode.recipientPhoneNumber}</td>
                    <td>{qrCode.recipientLogiHashID}</td>
                    <td>{qrCode.numberOfScans}</td>

                    {isAdmin && (
                      <td>
                        <button className="button-deactivate" onClick={() => deactivateQRCode(qrCode.id)}>
                          {t.deactivate}
                        </button>
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printQRCodepdf(qrCode.qrCodeUID)}>
                          {t.printQRCode} PDF
                        </button>
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printQRCodepng(qrCode.qrCodeUID)}>
                          {t.printQRCode} PNG
                        </button>
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        <button className="button-print" onClick={() => printEnvelopeQRCode(qrCode.qrCodeUID)}>
                          {t.printEnvelope}
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="table-main-container">
          <p>{t.emptyList}</p>
        </div>
      );
    }
  };

  return renderActiveQRCodes();
};

export default ActiveQRCodes;
